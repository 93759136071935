import classNames from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Collapse, Input, Label, Row } from 'reactstrap'

function ScopeOfWorkItem(props) {
    const {linkIndex,links,deleteCardItem,informationsChange,deleteCard,prevSelectImage,handleFiles,deleteImage,activeCollapse,setActiveCollapse,handleAddRowItemNested,handleRemoveItemRow,informationsChangeItem}=props
    const [isOpen, setisOpen] = useState(false)
    let changeOpenState=()=>{
        setisOpen(prevVal=>!prevVal)
        setActiveCollapse(linkIndex)
    }
    
    useEffect(() => {
        setisOpen(false)
        if(linkIndex==activeCollapse){
            setisOpen(true)
        }
    },[activeCollapse])
  return (
    <Fragment>
        <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                <button
                className={classNames(
                    "accordion-button",
                    "fw-medium",
                    "fw-bold",
                    "fs-5",
                    { collapsed: !isOpen }
                )}
                type="button"
                onClick={() => changeOpenState()}
                style={{ cursor: "pointer" }}
                >
                {links[linkIndex]?.titleEn?links[linkIndex]?.titleEn:'Scope of work title'}
                </button>
            </h2>
            <Collapse isOpen={isOpen} className="accordion-collapse">
                <div className="accordion-body">
                    <Row className="mt-3">
                        <Col>
                            <Label>Main Title (english)</Label>
                            <Input 
                                className="input_cus"
                                placeholder="Title"
                                value={links[linkIndex]?.titleEn}
                                onChange={(e) => informationsChange(e, linkIndex,"titleEn")}
                            />
                        </Col>
                        <Col>
                            <Label>Main Title (arabic)</Label>
                            <Input 
                                className="input_cus"
                                placeholder="عنوان"
                                dir="rtl"
                                value={links[linkIndex]?.titleAr}
                                onChange={(e) => informationsChange(e, linkIndex,"titleAr")}
                            />
                        </Col>
                    </Row>
                    <hr style={{ margin: "25px -20px 20px -20px" }} />

                    {
                        links[linkIndex]?.cards&&links[linkIndex]?.cards.map((item,index)=>(
                            <div key={item.id}>
                                <Row className="mt-3">
                                    <Col xs="12">
                                        <div >
                                            <Label htmlFor="productdesc" >
                                                Scope Icon
                                            </Label>
                                            <Input
                                                type="file"
                                                className="form-control form-control-lg "
                                                onChange={e => handleFiles(e,linkIndex,index)}
                                                style={{borderRadius: 0}}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="dropzone-previews" id="file-previews">
                                            {((item?.icon?.length!=0&&item?.icon)&&item)?
                                                <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                >
                                                    <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={item?.icon?.name}
                                                            src={item?.icon?.preview?item?.icon?.preview:item?.icon}
                                                            style={{ objectFit: "cover" }}
                                                        />
                                                        </Col>
                                                        <Col>
                                                        <Link to="#" className="text-muted ">
                                                            {item?.icon?.name}
                                                        </Link>
                                                        
                                                        </Col>
                                                        <Col>
                                                        <div className="image__options">
                                                            <button
                                                                className="p-0"
                                                                type='button'
                                                                onClick={e => prevSelectImage(e, item?.icon?.preview?item?.icon?.preview:item?.icon)}
                                                            >
                                                            <i className="far fa-eye  fs-5 p-0"></i>
                                                            </button>
                                                            <button
                                                            style={{ color: "#ff3838" }}
                                                            type='button'
                                                            onClick={e => deleteImage(linkIndex,index)}
                                                            >
                                                            <i className="bx bx-trash fs-4"></i>
                                                            </button>
                                                        </div>
                                                        </Col>
                                                    </Row>
                                                    </div>
                                                </Card>:null
                                                
                                            }
                                            </div>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <Label>Title (english)</Label>
                                        <Input 
                                            className="input_cus"
                                            placeholder="Title"
                                            value={item?.titleEn}
                                            onChange={(e) => informationsChangeItem(e, linkIndex,"titleEn",index)}
                                        />
                                    </Col>
                                    <Col>
                                        <Label>Title (arabic)</Label>
                                        <Input 
                                            className="input_cus"
                                            placeholder="عنوان"
                                            dir="rtl"
                                            value={item?.titleAr}
                                            onChange={(e) => informationsChangeItem(e, linkIndex,"titleAr",index)}
                                        />
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <Label>Description (English)</Label>
                                        <textarea
                                            className="form-control  mb-0"
                                            rows="5"
                                            placeholder="Description"
                                            value={item?.descriptionEn}
                                            onChange={e => informationsChangeItem(e, linkIndex,"descriptionEn",index)}
                                        />
                                    </Col>
                                    <Col>
                                        <Label>Description (Arabic)</Label>
                                        <textarea
                                            className="form-control  mb-0"
                                            rows="5"
                                            placeholder="وصف"
                                            value={item?.descriptionAr}
                                            onChange={e => informationsChangeItem(e, linkIndex,"descriptionAr",index)}
                                            dir="rtl"
                                        />
                                    </Col>
                                </Row>
                                {index !== 0 ? (
                                <Button
                                    color="none"
                                    variant="primary"
                                    className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                                    id="unknown-btn"
                                    style={{
                                        color: "#ff3838",
                                        boxShadow: "none",
                                        height: "45px",
                                        borderRadius: 0,
                                    }}
                                    onClick={e => {
                                        // handleRemoveRow(linkIndex)
                                        // handleRemoveItemRow(linkIndex,index)
                                        deleteCardItem(linkIndex,item.id,item?.status=='new'?'new':'old',index)
                                    }}
                                    >
                                    <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i>{" "}
                                    Delete
                                    </Button>

                                ) : null}
                                <hr style={{ margin: "25px -20px 20px -20px" }} />

                        </div>

                        ))
                    }
                    
                    <Button
                            onClick={() => {
                            handleAddRowItemNested(linkIndex)
                            }}
                            color="none"
                            className="mt-2 fs-5 fw-bold p-0 text-left"
                            style={btnStyle}
                        >
                            <i className="bx bx-plus-circle fs-4"></i>{" "}
                            <span>Add scope of work Item</span>
                        </Button>
                        {
                            linkIndex !== 0 &&
                            <hr style={{ margin: "25px -20px 20px -20px" }} />
                        }

                    
                    {linkIndex !== 0 ? (
                        <Button
                        color="none"
                        variant="primary"
                        className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                        id="unknown-btn"
                        style={{
                            color: "#ff3838",
                            boxShadow: "none",
                            height: "45px",
                            borderRadius: 0,
                        }}
                        onClick={e => {
                            // handleRemoveRow(linkIndex)
                            deleteCard(linkIndex,links[linkIndex].id,links[linkIndex]?.status=='new'?'new':'old')
                        }}
                        >
                        <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i>{" "}
                        Delete
                        </Button>

                    ) : null}
                </div>
            </Collapse>
        </div>


    </Fragment>
  )
}

export default ScopeOfWorkItem

const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0",
}
